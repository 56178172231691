export const environment = {
  production: true,
  cognito: {
    userPoolId: 'us-west-2_1J4rjNDPk',
    userPoolWebClientId: '151odj65j90s3uo7ncv3veu8dv',
  },
  transactionApi:'https://nhmu4cuse0.execute-api.us-west-2.amazonaws.com/',
  keepCashApi: {
    url: 'https://prod-services.2keepcash.com',
    auth_basic: {
      user: "core-service",
      pass: "K33pC4sH@S3cuR3!"
    }
  }
};
