import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { retry, catchError, tap, map } from 'rxjs/operators';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': 'Basic ' + btoa(environment.keepCashApi.auth_basic.user + ':' + environment.keepCashApi.auth_basic.pass)
  }),
};
const httpOptionsAWS = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': 'EwzESBtpJq1Eknsnc5SpD9D9NyN9TLPMlRy3HIa1'
  }),
};

@Injectable({
  providedIn: 'root'
})
export class KeeppayService {
  private restBaseUrl = '/keeppay/comercio';
  private userApiUrl = `${environment.keepCashApi.url}`;

  constructor(private http: HttpClient) { }



  getUser(email:string): Observable<any> {
    let url = this.userApiUrl + this.restBaseUrl + '/search/'+email
    return this.http.get<any>(url,httpOptions);
    
  }


  // getCargosSinFiltro(idComercio:string):Observable<any>{
  //   var resultado = this.containsOnlyNumbers(idComercio);
  //   let urlBaseFinal =""
  //   urlBaseFinal = `https://nhmu4cuse0.execute-api.us-west-2.amazonaws.com/dev/getCommerceData/${idComercio}`
    
  //   return this.http.get<any>(urlBaseFinal); 

  // }
  // containsOnlyNumbers(str:string) {
  //   return /^\d+$/.test(str);
  // }
  // getCargosConFiltro(idComercio:string):Observable<any>{

  //   var resultado = this.containsOnlyNumbers(idComercio);
  //   let urlBaseFinal =""
  //   urlBaseFinal = `https://nhmu4cuse0.execute-api.us-west-2.amazonaws.com/dev/getCommerceData/${idComercio}`
  //   var d = new Date();
  //   var toMorrow = new Date();
  //   toMorrow.setDate(d.getDate() + 1).toString()
  //   var datestring =  d.getFullYear()+"-" + ("0"+(d.getMonth()+1)).slice(-2) +"-" +("0" + d.getDate()).slice(-2) ;
  //   var nextString =  toMorrow.getFullYear()+ "-" + ("0"+(toMorrow.getMonth()+1)).slice(-2)+ "-" +("0" + toMorrow.getDate()).slice(-2) ;
  //   urlBaseFinal = `https://nhmu4cuse0.execute-api.us-west-2.amazonaws.com/dev/getCommerceDataByDate/${idComercio}?startTime=${datestring}&endTime=${nextString}`;
  //   return this.http.get<any>(urlBaseFinal); 
  // }


  /**
   * Cacha la operación Http que falló
   * Deja que la aplicación continue.
   * @param operation - Nombre de la operación que falló
   * @param result - Valor a retornar
   * @return {Observable} - Regresa el valor como Observable
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  
}
